const authors = [
  {
    name: "Noah Wong",
    bio:
      "I am an always curious, strategic-creative digital creator. I have a real passion for learning new digital technologies and front-end development. I created this website to track my journey and share things I've learned along the way. I am a husband, father, pet lover and currently work at Amazon Web Services (AWS) doing digital marketing. ",
    imageUrl: "profile_sq.jpg",
  },
  {
    name: "Macey Wong",
    bio: "She is one fluffy Sarplaninac who likes to eat Rubiks cubes.",
    imageUrl: "macey.jpg",
  },
  {
    name: "Hops Wong",
    bio: "Street dog from Taiwan that loves a good butt scratch.",
    imageUrl: "hops.jpg",
  },
  {
    name: "Jonah Wong",
    bio: "A small but feisty boy with a motor mouth.",
    imageUrl: "jonah.jpg",
  },
]

module.exports = authors
