import React from "react"
import { Link, graphql } from "gatsby"
import dayjs from "dayjs"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import authors from "../utils/authors"

import { AuthorProfile, Container, Layout, Section } from "../components"
import SEO from "../components/seo"

export default function Template({ data, location }) {
  const { mdx: post } = data
  const author = authors.find(x => x.name === post.frontmatter.author)
  return (
    <Layout>
      <SEO
        title="Notes"
        keywords={[`gatsby`, `noah wong`, `blog`]}
        description={post.frontmatter.description}
      />
      <Section backgroundColor="#fafafa;">
        <Container backgroundColor="#fafafa" maxWidth={980}>
          <Post>
            <PostDetails>
              <PostTitle>{post.frontmatter.title}</PostTitle>
              <PostAuthor>
                {post.frontmatter.author && `By: ${post.frontmatter.author}`}
              </PostAuthor>
              <PostDate>
                {dayjs(post.frontmatter.date).format("MMM DD, YYYY")}
                {post.frontmatter.length &&
                  ` | ${post.frontmatter.length} min read`}
              </PostDate>
              <PostLocation>{post.frontmatter.location}</PostLocation>
            </PostDetails>
            <PostBody>
              <MDXRenderer>{post.body}</MDXRenderer>
            </PostBody>
          </Post>
          <AuthorProfile
            postAuthor={author}
            authorImageFluid={data.file.childImageSharp.fluid}
          />
          <PostFooter>
            {post.frontmatter.tags.map(tag => (
              <PostTags key={Math.random()} to={`/tags/${tag}`}>
                <svg
                  style={{
                    width: "2rem",
                    verticalAlign: "middle",
                    width: "2rem",
                    marginRight: "0.2em",
                  }}
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="tags"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  className="svg-inline--fa fa-tags fa-w-20"
                >
                  <path
                    fill="currentColor"
                    d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0 0 14.059 33.941l211.882 211.882c18.744 18.745 49.136 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM112 160c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm513.941 133.823L421.823 497.941c-18.745 18.745-49.137 18.745-67.882 0l-.36-.36L527.64 323.522c16.999-16.999 26.36-39.6 26.36-63.64s-9.362-46.641-26.36-63.64L331.397 0h48.721a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882z"
                    className=""
                  ></path>
                </svg>
                {tag}
              </PostTags>
            ))}
          </PostFooter>
        </Container>
      </Section>
    </Layout>
  )
}

const Post = styled.div`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--gray-400);
  @media (min-width: ${props => props.theme.responsive.sm}) {
    background: var(--white);
    padding: 2rem;
    margin-top: 2rem;
    box-shadow: var(--shadow-md);
  }
`

const PostBody = styled.div`
  a {
    text-decoration: none;
    color: var(--primary);
    &:hover {
      color: var(--primary-light);
    }
  }
`

const PostDetails = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--gray-400);
`
const PostTitle = styled.h2`
  line-height: 1.7em;
  margin: 2rem 0 1rem;
  color: var(--primary);
`
const PostAuthor = styled.span`
  display: block;
  font-size: 1.6rem;
  margin-bottom: 0.4rem;
`
const PostDate = styled.span`
  display: block;
  font-size: 1.2rem;
  margin: 0 0 0.2rem;
`
const PostLocation = styled.span`
  display: block;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`
const PostTags = styled(Link)`
  background: var(--primary);
  border-radius: 0.5rem;
  color: var(--white);
  font-size: 1.6rem;
  margin-right: 1rem;
  padding: 0.6rem 0.8rem;
  text-decoration: none;
  transition: all 0.2s linear;
  &:hover {
    background: var(--primary-dark);
  }
`
const PostFooter = styled.div`
  margin: 1.6rem 0;
  @media (min-width: ${props => props.theme.responsive.sm}) {
    margin-top: 0;
  }
`
const Divider = styled.hr`
  border: 0;
  margin: 2rem;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
`

export const postQuery = graphql`
  query BlogByPostPath($path: String!, $imageUrl: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        author
        date
        length
        location
        path
        tags
        title
        description
      }
    }
    file(relativePath: { eq: $imageUrl }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
